<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <VeriffLogo />
          <h1>
            {{ $t("Great, your information has been succesfully received!") }}
          </h1>

          <div class="w-100 mt-4">
            <button class="btn btn-primary btn-block" @click="next()">
              {{ $t("Next step") }}
            </button>
            <button
              class="btn btn-outline-white btn-block mt-3"
              @click="
                $router.push({ name: 'ExpatStep6', params: { tenant_id: tenant.id } })
              "
            >
              {{ $t("Go back") }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <!-- <img src="../../../assets/images/expat/step6.png" alt=""> -->
          <RightPoints active="4" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AuthLayoutContent from "../../../components/common/AuthLayoutContent.vue";
import VeriffLogo from "./VeriffLogo.vue";
import RightPoints from "./RightPoints.vue";
import RightSection from "../../../components/common/RightSection.vue";

export default {
  components: {
    VeriffLogo,
    AuthLayoutContent,
    RightPoints,
    RightSection,
  },
  computed: {
    ...mapGetters("tenant", ["tenant"]),
  },
  data() {
    return {};
  },
  async mounted() {
    let tenant_id = this.$route.params.tenant_id;

    if (tenant_id) {
      await this.getTenant(tenant_id);
    }
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    next() {
      if (this.tenant.digid_failed) {
        this.$router.push({ name: "DigidUWV", params: { tenant_id: this.tenant.id } });
      } else {
        this.$router.push({ name: "ExpatStep7", params: { tenant_id: this.tenant.id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
