<template>
  <div class="d-flex mb-4">
    <img :src="veriff_logo" alt="" />
    <div class="ml-2">
      <div class="fw-bold">
        {{ $t("Identity verification:") }}
      </div>
      <span>
        {{ $t("Veriff") }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      veriff_logo: require("../../../assets/images/veriff-logo.png"),
    };
  },
};
</script>
